.settings-outlets-warning {
  margin-top: 36px;
  background: #fffce9;
  width: 528px;
  padding: 12px;
  margin-bottom: 36px;
  border-radius: 8px;
}

.settings-outlets-warning p {
  color: #bea802;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.outlet-button-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 528px;
}

.csv-download-btn {
  background: #f8f8f8;
  border-radius: 12px;
  border: 0;
  width: 195px;
  height: 60px;
  margin-top: 48px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  /* cursor:pointer; */
}

.cac-container {
  position: relative;
}

.cac-doc {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cac-doc p {
  color: #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.cac-container {
  width: 528px;
  height: 50px;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 24px;
}
