

.transactionsid-pagination{
    display:flex;
    justify-content: flex-end;
    margin-top:47.5px;
    margin-bottom: 47.5px;
 
}

.dashboard-transactinsid-transactions{
    margin-top:0px;
}





.dashboard-transactionid-transactions{
    /* height: 50vh;
    overflow-y: auto; */
    padding-top: 25px;
    padding-bottom: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(32, 32, 32, 0.05);
    border-radius: 5px;
    margin-top:60px;

    
}
.dashboard-transactionid-transaction-nav{
    display: flex;
    justify-content: space-between;
    margin: 60px 0px 48px 0px;
}
.dashboard-transactionid-transaction-nav-heading{
    display: flex;
}

.dashboard-transactionid-transaction-nav p{
    color: var(--default-links);
}
.dashboard-transactionid-transaction-nav-heading p{
    color: var(--black);
    font-size: 15px;
}
.dashboard-transactionid-transaction-nav-heading img{
    margin-right: 18px;
}

.dashboard-transactionid-fetch-flex:nth-child(1){
    border-top: none;
}
.dashboard-transactionid-fetch-flex{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EFEFEF;
    cursor: pointer;
    transition: background  300ms  ease-in-out;
}
.dashboard-transactionid-fetch-flex:hover{
    background: #F8F8F8;
    transition: background  300ms ease-in-out;
}


.dashboard-transactionid-transactions-list{
    
    padding-left: 25px;
}

.dashboard-transactionid-transactions-list p{
    width: 14vw;
    font-size: 14px;
    color: var(--default-links);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    height: 53px;
   
   
    
  
}
.dashboard-transactionid-transaction-header-flex{

    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    margin-bottom: 12px;
   
}
.dashboard-transactionid-transaction-header-flex p{
    color: #c4c4c4;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
}
.transaction-id-nav{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}