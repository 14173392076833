.profile-save-buttton img {
  width: 35px;
  height: 35px;
}

.naira-symbol {
  position: absolute;
  margin-top: 16px;
  margin-left: 13px;
  font-size: 16px;
  color: #757575;
}

.mb-neg {
  margin-bottom: -10px;
}
