.dashboard-transactions-container {
  padding: 40px 5% 0px 48px;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 2000;
}

.static {
  height: 100vh;

  position: fixed;
}

/* .modal{
    background-color: #FFFFFF;
    position: absolute;
    z-index: 14000;
    left:calc(50% - 358px);
    top:20%;
    border-radius: 10px;
    width: 530px;
    height: 520px;
    

  }
   */

.dashboard-transactions-navbar {
  display: flex;
  justify-content: space-between;
}

.dashboard-transactions-navbar-left p:nth-child(1) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.dashboard-transactions-navbar-left p:nth-child(2) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.dashboard-transactions-navbar-right {
  position: relative;
  display: flex;
}

.dashboard-transactions-navbar-right input {
  padding-left: 60px;
  align-self: center;
  width: 315px;
  height: 48px;
  border-radius: 10px;
  background: #f8f8f8;
  outline: none;
  border: none;
  font-family: "Qanelas Soft", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
  font-size: 14px;
  color: var(--default-links);
  font-weight: 400;
  border-radius: 10px;
  transition: background 300ms ease;
}

.dashboard-transactions-navbar-right input:focus {
  background: #fff;
  transition: background 300ms ease;
  /* border:1px solid #C4C4C4; */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 1px rgba(32, 32, 32, 0.05);
}

.dashboard-transactions-navbar-right input::placeholder {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.3px;

  /* grey/main */

  color: #c4c4c4;
}

.dashboard-transactions-navbar-right .search {
  position: absolute;
  z-index: 7000;
  top: 18px;
  left: 24px;
}

.dashboard-transactions-navbar-right-image1 {
  align-self: center;
  border: 1px solid #c4c4c4;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
}

.dashboard-transactions-navbar-right-image2 {
  align-self: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #fff1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
}

.dashbar-transactions-navbar-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-transactions-filter-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: var(--black);
}

.dashboard-transactions-transactions {
  /* height: 50vh;
    overflow-y: auto; */
  /* padding-top: 25px; */

  background: #ffffff;

  margin-top: 36px;
  height: 70vh !important;
  height: auto;
  overflow-y: auto;
  position: relative;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(32, 32, 32, 0.05);
     */
  /* border-radius: 20px; */
}

.dashboard-transactions-transaction-nav {
  display: flex;
  justify-content: space-between;
  margin: 60px 0px 48px 0px;
}

.dashboard-transactions-transaction-nav-heading {
  display: flex;
}

.mobile-right {
  display: none;
}

.dashboard-transactions-transaction-nav p {
  color: var(--default-links);
}

.dashboard-transactions-transaction-nav-heading p {
  color: var(--black);
  font-size: 15px;
}

.dashboard-transactions-transaction-nav-heading img {
  margin-right: 18px;
}

.dashboard-transactions-fetch-flex:nth-child(1) {
  border-top: none;
}

.dashboard-transactions-fetch-flex {
  border-top: 1px solid #efefef;
  cursor: pointer;
  transition: background 300ms ease-in-out;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 0px 0px;
}

.dashboard-transactions-fetch-flex:hover {
  background: #f8f8f8;
  transition: background 300ms ease-in-out;
}

.dashboard-transactions-transactions-list {
  color: var(--default-links);
}

.dashboard-transactions-transaction-header-flex {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px 13px 0px;
  border-bottom: 1px solid #d9d9d9;
}

.dashboard-transactions-transaction-header-flex p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  width: 14vw;
}

.dashboard-transactions-transaction-header-flex p:nth-child(1) {
  text-align: left;
}

.dashboard-transactions-transaction-header-flex p:nth-child(5) {
  text-align: right;
}

.dashboard-transactions-pagination {
  margin-top: 28px;
  color: #757575;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.dashboard-transactions-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
}

.dashboard-transactions-pagination-text {
  margin-top: -3px;
  padding: 0px 27px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.next img {
  cursor: pointer;
}

.next {
  width: 24px;
  height: 24px;
  background: #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previous img {
  /* filter: invert(50%) sepia(8%) saturate(0%) hue-rotate(161deg) brightness(88%) contrast(80%); */
  transform: rotate(180deg);
  cursor: pointer;
}

.previous {
  width: 24px;
  height: 24px;
  background: #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load {
  display: flex;
  justify-content: center;
  width: 100%;
}

.load p {
  margin-top: 25%;
}

.load img {
  display: flex;
  justify-content: center;
  margin-top: 25%;
  position: absolute;
  z-index: 30000;
  left: 45%;
}

.dashboard-transactions-transactions-list {
  width: 14vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.dashboard-transactions-transactions-list p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboard-transactions-transactions-list:nth-child(5) {
  text-align: right;
}

.filter-drop {
  width: 130px;
  height: 50px;
  position: relative;
  cursor: pointer;
  text-align: center;
}

.filter-drop input {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  outline: none;
  border: none;
  padding-left: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;

  color: #121212;
}

.filter-drop img {
  position: absolute;
  right: 12px;
  top: 22px;
}

.dashboard-transactions-navbar input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.filter-drop-value {
  width: 156px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 0px 24px;
  gap: 26px;
  position: absolute;
  z-index: 2000;
  bottom: -200px;
  transform: translateY(5px);
}

.filter-drop-value p {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  color: #757575;
}

.filter-radio-circle {
  width: 14px;
  height: 14px;
  background: var(--primary-color);
  border-radius: 50%;
}

.filter-radio {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
}

.filter-drop-container {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.filter-container-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
}

.clear-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 103px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  cursor: pointer;
}

.clear-filter p {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  letter-spacing: -0.3px;
}

.filter-drop-mobile {
  display: none;
}

.transactions-filter {
  margin-top: 36px;
}
.transactions-filter-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-selectors {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.or,
.to {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.or {
  margin: 0px 36px;
}

.to {
  margin: 0px 36px;
}

.transactions-filter-heading {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-right: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.selection-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-date-input {
  padding: 12px 12px;
  background: #f8f8f8;
  border-radius: 8px;
  width: 110px;
  height: 43px;
  border: 0;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/main */

  color: #c4c4c4;
}

.filter-date-input:focus {
  outline: 1px solid #d9d9d9;
}

.transactions-filter-selection {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 12px 24px;
  height: 43px;

  cursor: pointer;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  margin-right: 12px;
  transition: all 200ms ease;
}

.filter-date-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-apply,
.filter-clear {
  padding: 12px 24px;
  /* width: 122px; */
  height: 43px;
  left: 1093px;
  top: 220px;
  border-radius: 8px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  cursor: pointer;
}

.filter-apply {
  background: #f8f8f8;
  color: #757575;
  transition: all 200ms ease;
}

.filter-clear {
  color: #757575;
  margin-left: 12px;
  transition: all 200ms ease;
}

@media screen and (max-width: 975px) {
  .filter-drop {
    display: none;
  }

  .filter-drop-mobile {
    margin-top: 44px;
    margin-right: 33px;
  }
  .filter-drop img {
    position: absolute;
    right: 12px;
    top: 22px;
  }

  .filter-drop-mobile {
    display: block;
  }

  .filter-drop-value {
    right: 20%;
  }

  .clear-filter {
    display: none;
  }

  .dashboard-transactions-transaction-header-flex p:nth-child(1) {
    color: #c4c4c4;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    width: 14vw;
  }

  .dashboard-transactions-transaction-header-flex p:nth-child(2) {
    color: #c4c4c4;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    width: 14vw;
  }

  .dashboard-transactions-transaction-header-flex p:nth-child(3) {
    color: #c4c4c4;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    width: 14vw;
    text-align: right;
  }

  .dashboard-transactions-transaction-header-flex p:nth-child(4) {
    color: #c4c4c4;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    width: 14vw;
  }

  .dashboard-transactions-transactions-list:nth-child(1) {
    width: 14vw;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    height: 53px;
  }

  .dashboard-transactions-transactions-list:nth-child(2) {
    width: 14vw;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    height: 53px;
  }

  .dashboard-transactions-transactions-list:nth-child(3) {
    width: 14vw;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    height: 53px;
  }

  .dashboard-transactions-transactions-list:nth-child(4) {
    width: 14vw;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    height: 53px;
  }

  .dashboard-transactions-navbar {
    /* position: fixed;
       
        position:-webkit-sticky;
        top: 0;
        -webkit-overflow-scrolling:touch; */
    height: 85px;
    background-color: white;
    width: 100vw;
    z-index: 7000;
  }

  .dashboard-transactions-navbar-left p:nth-child(1) {
    position: absolute;
    top: 41px;
    left: 15vw;

    font-size: 16px;

    letter-spacing: -0.3px;

    color: #121212;
  }

  .dashboard-transactions-navbar-left p:nth-child(2) {
    display: none;
  }

  .dashboard-transactions-navbar-right input {
    display: none;
  }

  .dashboard-transactions-navbar-right .search {
    display: none;
  }

  .dashboard-transactions-navbar-right-image1 {
    border: none;
  }

  .dashboard-transactions-container {
    padding: 0px;
    height: 80vh;

    position: fixed;
    z-index: 100;
  }

  .dashboard-transactions-transaction-header-flex {
    width: 100%;

    padding: 24px 24px 13px 24px;
    margin-bottom: 0px;
    background: #f8f8f8;
  }

  .dashboard-transactions-transaction-header-flex p {
    color: #757575;
  }

  .dashboard-transactions--transaction-header-flex p:nth-child(2),
  .dashboard-transactions--transaction-header-flex p:nth-child(3) {
    width: 10px;
    color: #757575;
  }

  .dashboard-transactions-transaction-header-flex p:nth-child(1),
  .dashboard-transactions-transaction-header-flex p:nth-child(4),
  .dashboard-transactions-transaction-header-flex p:nth-child(5) {
    display: none;
  }

  .dashboard-transactions-transactions-list {
    padding: 0;
  }

  .dashboard-transactions-fetch-flex {
    width: 100%;
  }

  .dashboard-transactions-fetch-flex .dashboard-transactions-transactions-list {
    width: 50%;
    /* padding:0px 24px; */
  }

  .dashboard-transactions-fetch-flex
    .dashboard-transactions-transactions-list:nth-child(2)
    p {
    width: 100%;
    text-align: left;
    font-size: 14px;
  }

  .dashboard-transactions-fetch-flex
    .dashboard-transactions-transactions-list:nth-child(3)
    p {
    width: 100%;
    text-align: right;
    font-size: 14px;
  }

  .dashboard-transactions-fetch-flex
    .dashboard-transactions-transactions-list:nth-child(1),
  .dashboard-transactions-fetch-flex
    .dashboard-transactions-transactions-list:nth-child(4),
  .dashboard-transactions-fetch-flex
    .dashboard-transactions-transactions-list:nth-child(5) {
    display: none;
  }

  .dashboard-modal-flex-container {
    padding: 24px 24px 0px 24px;
  }

  .dashboard-modal-flex p:nth-child(1) {
    font-weight: 300;
  }

  .dashboard-modal-buttons {
    padding: 0px 24px;
  }

  .dashboard-modal-buttons button:nth-child(2) {
    display: none;
  }

  .refund-mobile {
    display: block;
    padding: 0;
    gap: 10px;
    width: 90px;
    height: 45px;
    background: #8f00ff;
    border-radius: 5px;
    border: none;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }

  .dashboard-modal-buttons button:nth-child(1) {
    padding: 0;
    width: 90px;
  }

  .dashboard-transactions-transactions {
    padding: 0;
    height: 90%;
    overflow-y: hidden;
    width: 90vw;
    margin: 20px auto 0px auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    box-shadow: none;
  }

  .dashboard-transactions-transaction-nav {
    width: 90vw;
    margin: 50px auto;
  }

  .dashboard-transactions-transaction-nav .transactions-text-none {
    display: none;
  }

  .dashboard-transactions-pagination {
    margin-left: 5vw;
    width: 226px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .dashboard-transactions-transactions-list:nth-child(2) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 400;
  }

  .dashboard-transactions-transactions-list:nth-child(3) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 500;
  }

  .mobile-right {
    display: block;
    position: absolute;
    right: 17px;
    top: 47px;
  }

  .dashboard-transaction-modal-heading p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: -0.3px;
    text-align: center;
    /* text */

    color: #757575;
  }

  .dashboard-transactions-transactions-list:nth-child(2) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 400;
  }

  .dashboard-transactions-transactions-list:nth-child(3) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 500;
  }

  .dashboard-transactions-fetch-flex:hover {
    background: none;
    transition: none;
  }

  .transactions-scroll {
    height: 80%;
    overflow-y: auto;
  }
}

.transactions-switch {
  margin-top: 60px;
}

.searchBox::placeholder {
  color: red;
}

@media screen and (max-width: 1400px) {
  .transactions-filter-flex {
    width: 90%;
  }
  .dashboard-transactions-transactions {
    height: 60vh !important;
  }
}

@media screen and (max-width: 1540px) {
  .transactions-filter-heading {
    padding-right: 10px;
    font-size: 14px;
  }
  .transactions-filter-selection {
    padding: 12px 12px;
    font-size: 14px;
  }
  .filter-date-input {
    padding: 12px 12px;
    font-size: 14px;
    width: 95px;
  }
  .or {
    margin: 0px 24px;
    font-size: 14px;
  }
  .to {
    font-size: 14px;
  }
  .filter-apply {
    font-size: 14px;
    padding: 12px 12px;
  }
  .filter-clear {
    font-size: 14px;
    padding: 12px 12px;
  }
  .dashboard-transactions-transaction-header-flex p:nth-child(1),
  .dashboard-transactions-transaction-header-flex p:nth-child(2),
  .dashboard-transactions-transaction-header-flex p:nth-child(3),
  .dashboard-transactions-transaction-header-flex p:nth-child(4),
  .dashboard-transactions-transaction-header-flex p:nth-child(5) {
    font-size: 16px;
  }
}
