.contemp-container {
  /* background:red; */
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5px;
  height: 120px;
}

.contemp {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.contemp-no {
  width: 20px;
  height: 20px;
  background: #f8f8f8;
  color: #757575;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  margin-right: 19px;
  transition: all 200ms ease;
}

.contemp img {
  width: 100px;
  height: 100px;
}

.chevyicon {
  color: #757575;
  margin-left: 12px;
}

@media screen and (max-width: 1550px) {
  .contemp-container {
    width: 50%;

    /* transform: translateX(-50%); */
  }

  /* .contemp{
      font-size:14px;
    } */
  .contemp-no {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .contemp-container {
    width: 60%;
    position: absolute;
    right: 50%;
  }
  /* .top-bar {
    zoom: 0.7;
  } */

  .contemp {
    font-size: 20px;
  }
}
@media screen and (max-width: 1260px) {
  .contemp-container {
    width: 60%;
    position: absolute;
    right: 50%;
  }
  /* .top-bar {
    zoom: 0.7;
  } */
}
@media screen and (max-width: 1080px) {
  .contemp-container {
    width: 70%;
    position: absolute;
    right: 40%;
  }
  /* .top-bar {
    zoom: 0.7;
  } */
}

@media screen and (max-width: 1450px) {
  /* .contemp {
    font-size: 14px;
  } */
}

@media screen and (max-width: 575px) {
  .top-bar {
    display: none;
  }
  .top-bar-right {
    display: none;
  }
}
