.forgot-container {
  margin: auto;
  margin-top: 50px;
  width: 528px;
}

.forgot-main-text {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.forgot-subtext {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.forgot-form {
  margin-top: 55px;
}

.forgot-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.forgot-form input {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.forgot-form input::placeholder {
  color: #c4c4c4;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}

.forgot-button {
  width: 528px;
  height: 60px;
  margin-top: 48px;
  outline: none;
  border: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #757575;
  background: #f8f8f8;
  border-radius: 5px;
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
}

a {
  text-decoration: none;
}

.ForgotToLogin {
  color: var(--primary-color);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.3px;
  text-align: center;
  margin-top: 60px;
}
.inputBoxForgot {
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
}

.forgot-to-login {
  text-align: center;
  margin-top: 36px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
}

@media screen and (max-width: 575px) {
  .forgot-container {
    width: 90vw;
    margin: 0 auto;
    margin-top: 70px;
    position: relative;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 40px;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .mobile-nav img {
    width: 112.91px;
  }
  .forgot-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputBoxForgot {
    width: 100%;
    margin-bottom: 25px;
    font-family: "Qanelas Soft";
  }
  .forgot-button {
    width: 100%;
    font-size: 16px;
    height: 52px;
  }
  .ShowforgotPass {
    transform: translate(80vw, 47px);
  }
  .forgot-main-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.3px;

    /* black/main */

    color: #121212;
  }
  .forgot-subtext {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;

    /* grey/text */

    color: #757575;
  }
  .forgotToForgot,
  .forgotToSignUp {
    display: none;
  }

  .forgot-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .forgot-form {
    margin-top: 36px;
  }
  .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
  }
  .ForgotToLogin {
    display: none;
  }

  .forgot-to-login {
    margin-top: 36px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
  }

  .forgot-to-signup {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    color: #757575;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
  }

  .forgot-form input {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    letter-spacing: -0.3px;
    padding-left: 16px;

    /* black/main */

    color: #121212;
    font-family: "Qanelas Soft", sans-serif;
  }
}
