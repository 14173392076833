.setting-payouts-form {
  margin-top: 35px;
}

.label {
  color: #757575;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.3px;
  margin-top: 36px;
  /* margin-bottom:1rem; */
}

.payouts-form-group {
  margin-top: 36px;
}

.payouts-form-group ul {
  width: 100% !important;
}

.payouts-form-group input {
  margin-top: 13px !important;
  margin-bottom: 0px !important;
}

.payouts-form-group .label-img {
  top: 50px;
}

.payouts-drop {
  margin-bottom: 30px;
}

.settingsInputBox {
  width: 528px !important;
  height: 50px !important;
  padding-left: 16px;

  margin-top: 8px;
  margin-bottom: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.selectBox {
  width: 528px;
  height: 41px;
  padding-left: 20px;
  color: var(--black);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid rgba(229, 231, 235);
  margin-top: 13px;
  border-radius: 5px;
}

select {
  -webkit-appearance: none;
}

.setting-payouts-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.setting-payouts-form select:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.payouts-save-button {
  width: 195px;
  height: 60px;
  margin-top: 48px;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  border-radius: 12px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  transition: all 200ms ease;
}

.profile-loading-save-button {
  width: 195px;
  height: 60px;
  margin-top: 48px;
  background-color: #8f00ff !important;
  color: #fff;
  border: 0;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.profile-loading-save-button img {
  width: 20px;
}

.settings-payouts-warning {
  margin-top: 36px;
  background: #fffce9;
  width: 528px;
  padding: 14px;
}

.settings-payouts-warning p {
  color: #bea802;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.erase-btn {
  margin-left: 26px;
  width: 195px;
  height: 60px;
  border: 0;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  color: #757575;
  background: transparent;
  letter-spacing: -0.3px;
  border: 1px solid #757575;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.erase-btn-loading {
  margin-left: 26px;
  width: 195px;
  height: 60px;
  border: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #757575;
  background: transparent;
  letter-spacing: -0.3px;
  border: 1px solid #757575;
  border-radius: 10px;
  cursor: default;
  transition: all 0.2s ease-in-out;
  /* transform: translateY(6px); */
  background: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
}

.erase-btn-loading img {
  width: 35px !important;
  height: 35px !important;
}

.payouts-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 528px;
}

.erase-btn:hover {
  background-color: #757575;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.erase-btn-loading img {
  width: 35px;
}

.getAccountLoader {
  width: 50px;
  height: 105px;
}

.getAccountLoader {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.getAccountLoader img {
  /* margin-left: 10%;
    margin-top: 10%; */
  margin-top: -10%;
}

.accNum-container {
  display: flex;
  justify-content: flex-start;
}

.accNum-loader {
  height: 41px;
}
