@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.cdnfonts.com/css/qanelas-soft");

:root {
  --primary-color: #8f00ff;
  --default-links: #757575;
  --black: #121212;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Qanelas Soft", sans-serif !important;
}

body {
  font-family: "Qanelas Soft", sans-serif;
  scrollbar-color: var(--primary-color);
  overflow-x: hidden;
  /* -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  overflow-y: hidden;
}
@media screen and (max-width: 1400px) {
  body {
    /* zoom: 0.7; */
    height: 100vh;
  }
}

::selection {
  background: #757575;
  color: #fff;
}

/* *::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}



*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
 
} */

/* ::-webkit-scrollbar {
  display: block;
} */

input:-webkit-autofill::first-line {
  font-family: "Poppins";
}

.new-user-screen {
  display: none;
}

.center img {
  width: 50px !important;
  height: 50px !important;
}

@media screen and (max-width: 575px) {
  .new-user-screen {
    width: 100vw;
    background: #121212;
    height: 100vh;
    position: absolute;
    display: block;
    z-index: 1000;
  }

  .new-user-screen-fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .not {
    display: none;
  }

  .new-user-screen-skip-btn {
    color: var(--default-links);
    position: absolute;
    right: 24px;
    top: 50px;
  }

  .new-user-screen-info {
    background: #f5f5f5;
    position: absolute;
    width: 100vw;
    height: 50vh;
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  .new-user-screen-info-text {
    width: 90vw;
    margin-top: 36px;
  }

  .new-user-screen-info-mainText {
    color: #121212;
    font-size: 32px;
    font-weight: 600;
    width: 100%;
  }

  .new-user-screen-info-subText {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.3px;
    margin-top: 24px;
    color: var(--default-links);
  }

  .new-user-screen-info-faq {
    color: #8f00ff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    text-align: center;
    position: absolute;
    bottom: 37%;
    left: 50%;
    transform: translateX(-50%);
  }
  .new-user-screen-info-faq a {
    text-decoration: none;
  }

  .new-user-screen-info-controls {
    margin-top: 40px;
    height: 60px;
    position: absolute;
    width: 90vw;
    bottom: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .new-user-screen-dots {
    display: flex;
    width: 60px;
    gap: 4px;
  }

  .control-dot {
    width: 6px;
    height: 6px;
    background: #d9d9d9;
    border-radius: 50%;
    transition: all 0.4s ease;
  }

  .active-dot {
    width: 24px;
    background: #121212;
    border-radius: 6px;
    /* animation-name: active-dot;
    animation-duration: .5s;
    animation-timing-function: linear; */
    transition: all 0.4s ease;
  }

  .new-user-screen-next-btn {
    width: 50px;
    height: 50px;
    background: #121212;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .purple-new-user-faq {
    color: #8f00ff;
  }

  .new-user-screen-next-btn img {
    width: auto;
  }

  .new-user-screen-gif img {
    width: 220px;
    height: 220px;
    /* background:red; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 14%;
  }

  .fade-out-new-user {
    -webkit-animation: fade-out 0.5s ease-out both;
    animation: fade-out 0.5s ease-out both;
  }

  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@keyframes removeNewScreen {
  0% {
    opacity: 1;
    /* left:0%; */
  }
  100% {
    opacity: 0;
    display: none;
    /* left:-100%; */
  }
}

@media screen and (max-height: 690px) {
  .new-user-screen-info-faq {
    bottom: 30%;
  }
  .new-user-screen-gif {
    top: 10%;
  }

  .new-user-screen-info-controls {
    bottom: 10%;
  }
}

@media screen and (max-width: 575px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.top-bar {
  width: 100%;

  height: 120px;
}

.top-bar-content {
  height: 100%;
  width: 90%;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.top-bar-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 120px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background:blue; */
}

.top-right-inner {
  position: absolute;
  right: 5%;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1450px) {
  .top-right-inner {
    font-size: 16px;
  }
}

.onb-main-text {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.onb-sub-text {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.onb-label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.onb-input {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  width: 528px;
  height: 49px;
  margin-top: 8px;
  padding-left: 24px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.onb-label-nf {
  margin-top: 24px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.onb-button {
  width: 523px;
  height: 60px;
  border-radius: 12px;
  border: 0;
  margin-top: 36px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;

  /* white */

  color: #fafafa;
  text-align: center;
}

.onb-button img {
  width: 40px;
  height: 40px;
}

.onb-purple-link {
  color: #8f00ff;
  margin-left: 0.2rem;
  /* text-decoration: underline; */
}

.no-mb {
  margin-bottom: 0px !important;
}

.app-purple {
  /* margin-left:.3rem;
  margin-right:.3rem; */
  color: #8f00ff;
}

.mt-24 {
  margin-top: 24px;
}
.un {
  text-decoration: underline;
}

.mobile-app {
  display: none;
}

.desktop {
  display: block;
}

button img {
  width: 35px;
  height: 35px;
}

.mt-36 {
  margin-top: 36px !important;
}

.remove-mbot {
  margin-bottom: 0px !important;
}

.neg-btn {
  margin-top: 25px !important;
}

@media screen and (max-width: 768px) {
  body {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .onb-main-text {
    display: none;
  }
  .onb-sub-text {
    display: none;
  }
  .mobile-app {
    display: block;
  }
  .desktop {
    display: none;
  }
}

/* ZOOMING OUT ON SMALLER SCREENS */

@media screen and (max-width: 1400px) {
  body {
    overflow-y: auto;
  }

  .cover {
    position: absolute;
    z-index: 1000;
    height: 200vh;
    left: 50%;
    top: 50%;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    width: 200vw;
  }

  .top-bar-right {
    /* position: absolute;
    top: 0;
    right: 0;
    height: 120px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center; */
    /* background:blue; */
  }

  .contemp-container {
    width: 50%;
    position: absolute;
    right: 50%;
    top: 10%;
  }

  .top-bar-content {
    height: 120px;
  }

  .SignUp-container,
  .Login-container,
  .otp-container,
  .kyc-container,
  .reset-container {
    margin-top: 0px !important;
  }

  .info-flex {
    width: 527.99px;
  }
  .SignUp-button {
    margin-bottom: 40px;
  }

  .kyc-submit-button {
  }

  .top-bar-right,
  .top-bar-content {
    zoom: 0.8;
  }

  .info .dashboard-home-navbar-left p:nth-child(1),
  .dashboard-home-navbar-left,
  .dashboard-payouts-navbar-left p:nth-child(1),
  .dashboard-payouts-navbar-left p:nth-child(2),
  .dashboard-home-accountDetails-boxes:nth-child(1),
  .dashboard-home-accountDetails-boxes,
  .dashboard-home-transaction-nav-heading p,
  .dashboard-home-fetch-flex,
  .dashboard-home-transaction-header-flex,
  #modalodd,
  .dashboard-home-navbar-right,
  .pop-white,
  .create-out,
  .name-circle,
  .switch-outlet-name,
  .switch-outlet-outlet,
  .dashboard-transactions-navbar-left p,
  .dashboard-transactions-navbar-right input,
  .dashboard-transactions-navbar-right .search,
  .transactions-filter-selection,
  .filter-date-input,
  .react-datepicker__month-container,
  .transactions-filter-heading,
  .filter-apply,
  .filter-clear,
  .dashboard-transactions-transaction-header-flex,
  .dashboard-transactions-fetch-flex,
  .or,
  .to,
  .dashboard-payouts-transaction-header-flex,
  .dashboard-payouts-fetch-flex,
  .organisation-nav,
  .left-table-details,
  .mobile-tab,
  .dashboard-settings-navbar,
  .settings-nav,
  .setting-profile-form,
  .scale-solo,
  .settings-outlets-warning,
  .setting-payouts-form,
  .outlet-button-flex,
  .settings-payouts-warning,
  .payouts-buttons,
  .create-outlet-container,
  .sidebar-support p,
  .sidebar-support img,
  .dashboard-sidebar-logout img,
  .dashboard-sidebar-logout p,
  .dashboard-copied,
  .logoutModal-container,
  .supportModal-container,
  .Login-container,
  .forgot-container,
  .SignUp-container,
  .otp-container,
  .info-flex,
  .business-type-flex p,
  .business-type p,
  .rolesModal,
  .modalid,
  .info-center,
  .dashboard-transactions-pagination,
  .payouts-buttons,
  .settings-save-button,
  .modal,
  .csv,
  .nea,
  .left-table-details,
  .middle-table-details,
  .right-table-details {
    zoom: 0.85;
  }
}

/* preloader */

.preloaderContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preLoader img {
  width: 60px;
  height: auto;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.puff-out-center {
  -webkit-animation: puff-out-center 300ms cubic-bezier(0.165, 0.84, 0.44, 1)
    both;
  animation: puff-out-center 300ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
