.extra{
    margin: 50px 0px 0px 48px ;
}

.extra p:nth-child(1){
    font-weight: 500;
    font-size: 16px;
    color: #757575;
    margin-bottom: 8px;
}

.extra p:nth-child(2){
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #121212
}