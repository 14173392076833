.csv {
  width: 492px;
  height: 269px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  z-index: 8000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.csv h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #121212;
  margin-top: 48px;
}

.csv .date-pick {
  margin-top: 41px;
}

.csv-btns {
  margin-top: 36px;
}

.filter-date {
  width: 114px;
  height: 43px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none;
  padding-left: 12px;
  background: #f8f8f8;
  height: 43px;
  border: 0;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #c4c4c4;
}
.filter-date:nth-child(2) {
  position: absolute;
  right: 0;
}
.t {
  align-self: center;
  font-weight: 600;
  font-size: 16px;
  color: #757575;
  text-align: center;
}
/* .react-datepicker-wrapper{
   display: flex;
   justify-content: center;
} */
.csv button {
  background: #f8f8f8;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
