.tabs-flex{
    display: flex;
    justify-content: space-between;
}
.tabs-flex-left{
    display: flex;
    gap: 12px;
}
.tabs-flex-left p{
    align-self: center;
    font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
/* identical to box height */

letter-spacing: -0.3px;

/* black/main */

color: #121212;

}
.tabs-flex-right{
    align-self: center;
}
.tabs-flex-right img{
    align-self: center;
}
.settings-tabs{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 36px;
}
.pop-button{
    display: flex;
    justify-content: space-between;
}
.pop-button button:nth-child(1){
    border: none;
    color: #757575;
    background: none;
    font-weight: 600;
font-size: 16px;
line-height: 20px;
}
.pop-button button:nth-child(2){
    border: none;
    color: #FF3737;
    background: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 54px;
    background: #FFF1F1;
    border-radius: 10px;
    width: 124px;
}