.SignUp-container {
  margin: auto;
  margin-top: 50px;
  width: 528px;
}

.SignUp-main-text {
  letter-spacing: -0.3px;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.SignUp-subtext {
  color: #757575;
  letter-spacing: -0.3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 4px;
}

.SignUp-form {
  margin-top: 55px;
}

.SignUp-form input::placeholder {
  color: #c4c4c4;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}

.ShowPass-sign {
  position: absolute;
  z-index: 10;
  transform: translate(200px, 46px);
  color: #757575;

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
}

.SignUp-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.emailError {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
  transform: translateY(-30px);
}

.name-label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}

.label {
  margin-top: 34px;
}

.inputboxSignup {
  width: 528px;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding-left: 24px;
  margin-top: 11px;
  margin-bottom: 25px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
  transition: all 200ms ease;
}

.inputboxSignup::placeholder {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.passwordBox {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 244px;
  height: 50px;
  padding-left: 24px;
  padding-right: 50px;
  margin-top: 11px;
  font-family: "Qanelas Soft", "Sans-serif" !important;
}

.SignUp-password-area {
  display: flex;
  justify-content: space-between;
}
.policy {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* regular text */

  color: #757575;
  margin-top: 36px;
}

.purple {
  color: var(--primary-color);
  text-decoration: underline;
}

.SignUp-button {
  width: 528px;
  height: 60px;
  margin-top: 24px;
  outline: none;
  border: 0;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #757575;
  background: #d9d9d9;
  border-radius: 12px;
  transition: all 200ms ease;
}
.SignUp-button img {
  transform: translateY(3px);
  width: 35px;
}

.greyed-out {
  color: #c4c4c4;
}

.SignUpToLogin {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.passError {
  color: red;
  font-size: 12px;
  width: 244px;
  margin-top: 5px;
  position: absolute;
}

.static-app-top {
  display: none;
}

.desktop-name-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.smallInput {
  width: 244px;
  height: 50px;
  transition: all 200ms ease;
}

@media screen and (max-width: 575px) {
  .desktop-name-flex {
    display: block;
  }

  .smallInput {
    width: 100%;
  }

  .SignUp-container {
    width: 90vw;
    margin: 0 auto;
    /* height: 120vh; */
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 55px;
  }
  .mobile-nav img {
    display: none;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .SignUp-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputboxSignup {
    width: 100%;
    padding-left: 16px;
    font-family: "Qanelas Soft", "Sans-serif" !important;
  }
  .SignUp-button {
    width: 100%;
    font-size: 16px;
    height: 52px;
    border-radius: 12px;
    margin-top: 24px;
  }
  .ShowSignUpPass {
    transform: translate(80vw, 47px);
  }
  .SignUp-main-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .SignUp-subtext {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }
  .SignUpToForgot,
  .SignUpToSignUp {
    display: none;
  }

  /* .password-label,
    .email-label{
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: #121212;
    } */
  .SignUp-form {
    margin-top: 36px;
  }
  .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
  }
  .SignUp-password-area {
    width: 100%;

    flex-direction: column;
  }
  .SignUp-password-area label {
    width: 100%;
    position: relative;
  }
  .passwordBox {
    width: 100%;
    margin-bottom: 25px;
  }
  .password-label {
    margin-top: 0px;
  }
  .name-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .label-sign {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .ShowPass-sign {
    width: 100%;
    position: absolute;
    transform: translate(85%, 46px);
  }
  .SignUpToLogin {
    display: none;
  }
  .SignUp-subtext {
    display: none;
  }
  .policy {
    display: none;
  }
  .emailError {
    transform: translateY(-25px);
  }
  .passError {
    transform: translateY(-25px);
  }

  /* APP MODS */

  .app-none {
    display: none;
  }

  .mobile-signUp-mainText {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.3px;
  }

  .name-label,
  .label-sign {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: #757575 !important;
    font-family: "Qanelas Soft", "Sans-serif" !important;
  }

  .inputboxSignup {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
  .ShowPass-sign {
    display: none;
  }
  .passwordBox {
    font-family: "Qanelas Soft", "Sans-serif" !important;
  }
  .SignUp-form input::placeholder {
    color: #c4c4c4;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
  }
  .passwordBox input::placeholder {
    color: #c4c4c4;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
  }

  .SignUp-container {
    /* position:relative; */
  }

  .signUp-fixedTop {
    width: 100vw;
    background: #fdfbf9;
    height: 200px;
    z-index: 1000;
  }

  .mobile-nav {
    position: absolute;
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .static-app-top {
    /* position:fixed; */
    display: block;
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100vw;
    background: #fff;
    height: 170px;
    z-index: 1000;
    display: flex;
    justify-content: flex-start;

    /* display:none; */
  }
  .static-app-bottom {
    position: sticky;
    bottom: 0;
    left: 0%;
    /* transform:translateX(-50%); */
    width: 90vw;
    background: #fff;

    /* height:auto; */
    /* height:10px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    z-index: 1000;
  }

  .static-app-bottom-container {
    width: 90vw;
    margin: auto;
  }

  .app-signup-bottomtext {
    margin-top: 10%;
    font-weight: 500;
  }

  .SignUp-button {
    margin-top: 7%;
    border-radius: 12px;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    background: #d9d9d9;
    color: #fff;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
  }

  .static-app-textarea-top {
    width: 90vw;
    /* margin:auto; */
  }

  .static-app-maintext {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 70px;
  }
  .SignUp-form {
    /* margin-top:40%; */
    margin-top: 0px;
  }
  .last-onb-input {
    margin-bottom: 0%;
  }

  .app-signup-bottomtext {
    display: flex;
    flex-direction: column;
  }

  .app-signup-toLogin {
    margin-top: 10%;
    text-align: center;
    padding-bottom: 40px;
    color: #757575;
    /* position:absolute;
        bottom:30px; */
    /* text-align: center; */
    /* left:50%;
        transform: translateX(-50%); */
  }

  .app-purple {
    color: #8f00ff;
    font-weight: 500;
  }
  .SignUp-form input {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    border-radius: 10px;
  }

  .greyed-out {
    color: #c4c4c4;
    margin-left: 5px;
  }

  .static-app-subtext {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;
    color: #757575;
  }

  .app-signup-tapl {
    color: #757575;
  }

  .passwordBox {
    padding-left: 16px;
  }

  .app-passError {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: #ff3737;
  }

  .label-sign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .label-sign-right {
  }

  .SignUp-button img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 690px) {
  .SignUp-button {
    margin-top: 5%;
  }

  .app-signup-bottomtext {
    margin-top: 8%;
    font-weight: 500;
  }
  .app-signup-toLogin {
    margin-top: 8%;
  }
}
