.dashboard-container {
  display: flex;
  width: 100%;

  height: 100vh;
  text-align: left;
  overflow-y: auto;
  /* overflow-y: hidden;  */
}
.dashboard-outlet {
  /* position:absolute;
  right: 2vw;
  left: 240px;
  height: 100vh;
  text-align: left;
  overflow-y: auto;  */
  width: calc(100% - 240px);
}
.pending-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pending-container p {
  font-size: 25px;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 2s ease-in-out;
  height: 90vh;
}
.animation img {
  width: 40px;
  animation: 2s qevolt infinite;
}
@keyframes qevoltt {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(30px);
    opacity: 1;
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: scale(1.7);
    opacity: 0.01;
  }
}
@-webkit-keyframes qevolt {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes qevolt {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@media screen and (max-width: 1400px) {
  .dashboard-container {
    display: flex;
    width: 100%;

    height: 100vh;
    text-align: left;
    overflow-y: auto;
    /* overflow-y: hidden;  */
  }
}

@media screen and (max-width: 575px) {
  .dashboard-container {
    height: 90vh;
    padding: 0px 0px 0px 0px;

    overflow: hidden;
  }

  .dash-first {
    padding: 0;
    height: 100vh;
    overflow: hidden;
  }

  .dash-back {
    height: 90vh;
    padding: 0;

    overflow: hidden;
  }
  .dashboard-outlet {
    position: relative;
    right: 0;
    left: 0;
    text-align: left;
    overflow-y: hidden;
  }
}

.create-outlet-container h1 {
  margin-top: 0px;
  margin-left: 24px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.cr-out-label {
  margin-left: 24px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.cr-out-btn-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch-warning {
  background: #fffce9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 444px; */
  height: auto;
  margin-top: 108px;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
}

.switch-warning p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* safety/main */

  color: #bea802;
}

.getbusinessLoader img {
  width: 100px !important;
  height: 100px !important;
}
.outlet_id {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #c4c4c4;
}

@media screen and (max-width: 1400px) {
  .outlet_id {
    zoom: 0.8;
    font-size: 18px;
  }
}
