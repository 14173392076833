.orgranisationId {
  padding: 40px 5% 68px 48px;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 2000;
}
.orgranisationId-line-container {
  margin: 65px 0px 0px 0px;
  padding-left: 20px;
  padding-bottom: 18px;
}

.organisationId-line-items {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
.organisation-line-box {
  position: relative;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.organisation-line-box p {
  text-align: center;
  position: absolute;
  margin-bottom: 20px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  cursor: pointer;
  transition: color 250ms ease-in-out;
}
.organisationId-line {
  height: 3px;
  width: 40px;
  background: var(--primary-color);
  border-radius: 20px;
  transform: translateY(18px);
}

.organisations-border-bottom {
  border-bottom: 1px solid #f8f8f8;
  margin-left: -48px;
  width: calc(100vw - 240px);
  /* position: absolute; */
}

.dashboard-organisationid-navbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.organisation-branch-name {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}
.organisation-branch-id {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  margin-top: 12px;

  /* grey/text */

  color: #757575;
}

.organisationid-back {
  margin-right: 24px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
