.Login-container {
  margin: auto;
  margin-top: 50px;
  width: 528px;
}
.mobile-nav {
  display: none;
}
.Login-main-text {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.Login-subtext {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */
  margin-top: 12px;
  color: #757575;
}

.Login-button img {
  width: 35px;
  height: 35px;
}

.Login-form {
  margin-top: 55px;
}
.inputboxLogin {
  font-family: "Qanelas Soft", "sans-serif";
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
  font-size: 14px;
  margin-bottom: 25px;
}

.Login-form input::placeholder {
  color: #c4c4c4;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}

.Login-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.purple {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 500;
}

.LoginToForgot {
  display: block;
  margin-top: 36px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.Login-button {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 0;
  margin-top: 48px;
  width: 528px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  color: #757575;
  font-family: "  Qanelas Soft";
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
  border-radius: 12px;
}

.LoginToSignUp {
  display: none;
  text-align: center;
  margin-top: 60px;
}

.ShowLoginPass {
  position: absolute;
  z-index: 10;
  transform: translate(480px, 46px);
  color: #757575;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}
.forgot-mobile {
  display: none;
}

input:-webkit-autofill::first-line {
  font-family: "Qanelas Soft", "sans-serif";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: "Qanelas Soft", "sans-serif";
}

@media screen and (max-width: 575px) {
  .Login-form {
    /* margin-top:30%;
        height:calc(100vh - 30%); */
    margin-top: 36px;
  }

  .Login-container {
    width: 90vw;
    margin: 0 auto;
    position: relative;
    /* height:100vh; */

    overflow-y: hidden;
    margin-top: 70px;
  }

  .Login-main-text {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .Login-subtext {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;
    color: #757575;
  }
  .LoginToForgot {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .Login-button {
    border-radius: 12px;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
    margin-top: 24px;
  }

  .login-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .login-auth img {
    width: 16px;
    height: 19px;
  }

  .loginauthText {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }

  .Login-container {
    /* position:relative; */
  }

  .login-to-signup {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
    width: 100%;
    color: #757575;
  }

  .Login-form input {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    border-radius: 10px;
  }

  .Login-button img {
    width: 35px;
    height: 35px;
  }

  .login-to-signup {
    display: none;
  }
  .bottomonb-link {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    /* width:100%; */
    font-family: "Qanelas Soft";

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;

    /* grey/text */

    color: #757575;
    margin: auto;
  }

  .bottomLink {
    position: absolute;
    bottom: 0px;
  }
}
