.status {
  padding: 5px 0px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.3px;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-payouts-container {
  padding: 40px 48px 68px 48px;
  padding-bottom: 0px;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 2000;
}

.res-p {
  position: absolute;
  transform: translateY(5px);
}
.withdraw-modal {
  width: 676px !important;
  height: 549px !important;

  padding: 0px;
}

.dashboard-modal-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 36px;
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.payouts-warning {
  background: #fffdec;

  margin-left: 24px;
  margin-right: 24px;
  border-radius: 5px;
  margin-top: 16px;
  height: 64.21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  /* width:100%; */
}
.payouts-warning p {
  font-size: 14px;
  color: #bea802;
  padding: 10px;
}

.payouts-modal-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}

.payouts-modal-accounts-right {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.payouts-modal-account,
.payouts-modal-balance,
.payouts-modal-fee {
  display: flex;
  justify-content: space-between;
}
.payouts-modal-account,
.payouts-modal-fee {
  margin-top: 24px;
}

.payouts-modal-balance-right,
.payouts-modal-fee-right,
.payouts-modal-accounts-right {
  font-family: "Poppins";

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.dashboard-modal-payouts-input {
  margin-top: 36px;
  margin-bottom: 20px;
}
.dashboard-modal-payouts-input label {
  display: block;
  margin-bottom: 5px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
  letter-spacing: -0.3px;
  color: #757575;
}
.dashboard-modal-payouts-input input {
  width: 100%;
  height: 41px;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 22px;

  outline-color: var(--primary-color);
  outline-width: 0.3px;

  font-family: "Poppins", sans-serif;
  padding-left: 20px;
}
.dashboard-modal-payouts-input input:nth-child(6) {
  width: 100%;
  height: 80px;
  border: 1px solid #efefef;
  border-radius: 5px;

  outline-color: var(--primary-color);
  outline-width: 0.3px;

  font-family: "Poppins", sans-serif;
  padding-left: 20px;
}

.form-payouts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.payouts-modal-cancel {
  padding: 12px 36px;
  border: 0;
  border-radius: 5px;
  background: #f8f8f8;
  cursor: pointer;
}

.payouts-modal-cancel p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.3px;
  color: #757575;
}

.payouts-modal-submit {
  width: 121px;
  height: 45px;
  border: 0;
  border-radius: 5px;
  background: var(--primary-color);
  cursor: pointer;
  font-family: "Qanelas Soft";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.3px;
  color: #757575;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load {
  position: absolute;
}

.payouts-modal-submit img {
  width: 35px;
  height: 35px;
}

.payouts-modal-submit p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.3px;
  color: #ffffff;
}

.dashboard-payouts-navbar {
  display: flex;
  justify-content: space-between;
}

.dashboard-payouts-navbar-left p:nth-child(1) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}
.dashboard-payouts-navbar-left p:nth-child(2) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.dashboard-payouts-navbar-right button {
  background: var(--primary-color);
  color: #fafafa;
  padding: 12px 36px;
  border-radius: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  outline: 0;
  border: 0;
  font-family: "Poppins";
}

.dashboard-payouts-transactions {
  /* padding-top: 25px;
    padding-bottom: 25px; */
  background: #ffffff;
  padding-bottom: 25px;
  border-radius: 5px;
  margin-top: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  /* min-height:75vh; */
  overflow-y: hidden;

  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(32, 32, 32, 0.05); */
  /* height:auto !important; */
}
.dashboard-payouts-transaction-nav {
  display: flex;
  justify-content: space-between;
  margin: 60px 0px 48px 0px;
}
.dashboard-payouts-transaction-nav-heading {
  display: flex;
}

.dashboard-payouts-transaction-nav p {
  color: var(--default-links);
}
.dashboard-payouts-transaction-nav-heading p {
  color: var(--black);
  font-size: 15px;
}
.dashboard-payouts-transaction-nav-heading img {
  margin-right: 18px;
}

.dashboard-payouts-fetch-flex:nth-child(1) {
  border-top: none;
}
.dashboard-payouts-fetch-flex {
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid #EFEFEF; */
  cursor: pointer;
  transition: background 300ms ease-in-out;
  padding: 0px 0px;
  border-top-right-radius: 0px;
}
.dashboard-payouts-fetch-flex:hover {
  background: #f8f8f8;
  transition: background 300ms ease-in-out;
}

.dashboard-payouts-transactions-list {
  width: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.dashboard-payouts-transactions-list p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dashboard-payouts-transaction-header-flex {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px 13px 0px;
  /* margin-bottom: 12px; */
  border-bottom: 1px solid #d9d9d9;
}
.dashboard-payouts-transaction-header-flex p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.dashboard-payouts-transaction-header-flex p {
  width: 9vw;
}

.dashboard-payouts-transaction-header-flex p:nth-child(6) {
  text-align: right;
}

.dashboard-payouts-transactions-list p:nth-child(6) {
  text-align: right;
}

.dashboard-payouts-transactions-list p:nth-child(6) {
  text-align: right;
  justify-content: right;
}

.dashboard-payouts-transactions-list:nth-child(6) {
  justify-content: center;
  align-items: flex-end;
}

.dashboard-payouts-transactions-list,
.dashboard-payouts-transactions-list p {
  /* width: 14vw; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  gap: 10px;
}

.dashboard-payouts-transaction-header p:nth-child(1) {
  text-align: right;
}

.dashboard-payouts-transaction-header p:nth-child(1) {
  text-align: left;
}
.dashboard-payouts-transaction-header p:nth-child(5) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

/* .trans-id {
  text-align: right;
} */
/* .dashboard-payouts-transaction-header p:nth-child(5) {
  text-align: right;
} */

@media screen and (max-width: 1400px) {
  .payouts-form-group {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 1350px) {
  .dashboard-payouts-transaction-header-flex p {
    font-size: 16px;
  }
}
