.dashboard-organisations-container {
  padding: 40px 5% 0px 48px;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 2000;
}

.dashboard-organisations-navbar {
  display: flex;
  justify-content: space-between;
}

.dashboard-organisations-navbar-left p:nth-child(1) {
  color: var(--black);
  font-size: 20px;
  line-height: 31px;
  font-weight: 500;
  letter-spacing: -0.3px;
}
.dashboard-organisations-navbar-left p:nth-child(2) {
  color: var(--default-links);
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: -0.3px;
}
.dashboard-organisation-navbar-right {
  align-self: center;
}
.dashboard-organisation-navbar-right button {
  color: white;
  background: #8f00ff;
  border: none;
  width: 146px;
  height: 45px;
  border-radius: 5px;
  font-family: "poppins", sans-serif;
  margin-left: 48px;
  cursor: pointer;
}
.dashboard-organisation-navbar-right button:nth-child(1) {
  color: #757575;
  background: #f8f8f8;
  border: none;
  width: 146px;
  height: 45px;
  border-radius: 5px;
  font-family: "poppins", sans-serif;
}

.dashboard-organisation-navbar-right img {
  transform: translateY(03px);
  align-self: center;
  width: 15px;
}
.dashboard-modal-organisation-input {
  margin-left: 36px;
  margin-right: 36px;
  margin-top: -10px;
  margin-bottom: 20px;
}
.dashboard-modal-organisation-input label {
  display: block;
  margin-bottom: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 15px;
  letter-spacing: -0.3px;
  color: #757575;
}
.dashboard-modal-organisation-input input {
  width: 100%;
  height: 38px;
  border: 1px solid #efefef;
  border-radius: 5px;

  outline-color: var(--primary-color);
  outline-width: 0.3px;

  font-family: "Poppins", sans-serif;
  padding-left: 20px;
}
.dashboard-modal-organisation-input input:nth-child(6) {
  width: 100%;
  height: 80px;
  border: 1px solid #efefef;
  border-radius: 5px;

  outline-color: var(--primary-color);
  outline-width: 0.3px;

  font-family: "Poppins", sans-serif;
  padding-left: 20px;
}

#invites-text {
  background: #fffdec;
  margin-left: 36px;
  margin-right: 36px;
  border-radius: 5px;
  margin-top: 28px;
  height: 30px;
  display: flex;
  justify-content: left;
  position: relative;
}
#invites-text p {
  position: absolute;
  top: -3px;
  left: 10px;
}
.invitation-flex-container {
  display: flex;
  justify-content: space-between;
  padding: 36px 24px 0px 24px;
}
.invitation-left {
  display: flex;
  align-self: center;
}
/* .invitation-right button:nth-child(1){
    font-family: 'Poppins', sans-serif;
    border: none;
    background: #F8F8F8;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
} */
.invitation-right button {
  font-family: "Poppins", sans-serif;
  color: white;
  border: none;
  background: var(--primary-color);
  width: 100px;
  height: 100%;
  border-radius: 8px;
  margin-left: 20px;
  cursor: pointer;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}
.invite-cirle {
  background: #d9d9d9;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.invite-cirle p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;

  /* white/tint */

  color: #f4f4f4;
}

.invite-text p:nth-child(1) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}
.invite-text p:nth-child(2) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 4px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
}

.invitation-container {
  height: 285px;
  overflow-y: auto;
}
.loa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50%;
  margin: 50px auto;
}

.invites-error {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  text-align: center;
  position: absolute;
}

.question-mark {
  background-color: transparent;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f8f8f8;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  cursor: pointer;
}

.question-mark:hover {
}

.members-btn {
  width: 195px;
  height: 60px;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
  margin-left: 20px;
  border-radius: 12px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
}

.organisation-maintext {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.organisation-subtext {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.organisation-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.members-btn-flex {
  display: flex;

  top: 25px;
}

.mt-24 {
  margin-top: 24px;
}

.addMem-input {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.membtn-group {
  width: 444px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  bottom: 36px;
}

.membtn-group button {
  padding: 12px 24px;
  outline: 0;
  border: 0;
  border-radius: 8px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  cursor: pointer;
}

.role-drop-img {
  position: absolute;
  right: 19.2px;
  top: 45px;
  cursor: pointer;
}

.role-cont {
  position: relative;
}
.org-border-bottom {
  border-bottom: 1px solid #f8f8f8;
  margin-left: -48px;
  width: 100%;
  /* margin-top: 40px; */
  /* width:100vw; */
}

.no-pending {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
