.OnBoarding-container {
  /* width:100vw;; */
  display: flex;
  /* overflow-y:none; */
}
@media screen and (max-width: 575px) {
  .OnBoarding-container {
    width: 95%;
    padding-top: 0px;
    margin: 0px auto;
    overflow: hidden;
    height: 100vh;
    overflow-y: auto;
  }
}
