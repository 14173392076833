.q-label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  /* margin-top: 36px; */
  margin-left: 24px;
}
.q-input {
  width: 444px;
  height: 49px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  outline: none;
  margin-top: 6px;
  margin-bottom: 24px;
  padding-left: 10px;
  margin-left: 24px;
  margin-right: 24px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.q-input:focus {
  outline: 1px solid #8f00ff;
  border: none;
}
.q-input::placeholder {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}
.q-button {
  padding: 12px 24px;
  background: #8f00ff;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 36px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  transition: all 200ms ease;
}
.q-message {
  background: #fffce9;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 444px; */
  height: auto;
  margin-top: 108px;
  margin-left: 24px;
  margin-right: 24px;
}
.q-message p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* safety/main */

  color: #bea802;
}
.cr-out-button {
  transition: all 200ms ease;
}
