.logs-container{
    margin-top:35px;
}

.logs-datetime{
    color: #757575;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom:13px;
}

.log{
    color:var(--black);
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin-bottom:23px;
}