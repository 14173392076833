.members-edit p {
}

.dashboard-membersId-transactions-list {
  padding-left: 0px;
}

.resend-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resend-loader {
  width: 15px;
}

.mb-1 {
  margin-bottom: 36px;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.invitation-container {
  padding-bottom: 20px;
}

.dashboard-membersId-transactions-list p {
  width: 17.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.dashboard-membersId-transaction-header-flex {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  padding: 24px 24px 13px 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: none;
}

.dashboard-membersId-transaction-header-flex p {
  color: #757575;
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;
  width: 17.5vw;
}

.modalid {
  background-color: #ffffff;

  position: absolute;
  z-index: 8000;
  left: calc((100% - 560px) / 2);
  top: calc((100% - 530px) / 2);

  border-radius: 10px;
  width: 560px;
  height: 470px;

  -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.role-drop {
  /* width: 100vw; */
  background-color: white;
  list-style: none;
  margin-top: 8px;
  border-radius: 5px;
  top: 5px;
  z-index: 1000;
  height: auto;

  background: white;

  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.role-drop li {
  padding: 10px 20px;
  padding-left: 14px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;

  transition: 0.1s ease-in-out;
}

.role-drop li:hover {
  color: white;

  transition: 0.1s ease-in-out;
}

.membtn-group button {
  padding: 12px 24px;
  outline: 0;
  border: 0;
  border-radius: 8px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  cursor: pointer;
}

.memAdd {
  color: #ffffff;
  background: #8f00ff;
  width: 138px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memCancel {
  color: #757575;

  background: #f8f8f8;
}

.member-modal-heading {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.members-label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 36px;
  margin-bottom: 8px;
}

.rolesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 492px;
  height: 544px;

  background: #ffffff;
  border-radius: 10px;
  z-index: 10000;

  -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.rolesModal-header {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  /* border-bottom: 1px solid #EFEFEF; */
  padding-left: 24px;
}

.rolesModal-header p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
  margin-top: 36px;
  padding-bottom: 12px;
}

.rolesModal-body {
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0px;
  overflow-y: auto;
  height: 370px;
}

.rolesModal-object-header {
  margin-bottom: 12px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.mt-36 {
  margin-top: 36px;
}

.rolesModal-object-body {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  text-align: left;
}

.rolesModal-object-body {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  text-align: left;
}

.rolesModal-close-btn {
  background: #f8f8f8;
  border-radius: 8px;
  width: 121px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;
  border: 0;
  margin-left: 24px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.mb-30 {
  padding-bottom: 10px;
}

.react-datepicker__day--selected {
  background: #8f00ff;
}
.react-datepicker__day--selected:hover {
  background: #8f00ff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #8f00ff;
}

/* .react-datepicker__header{
    background:#8F00FF;
} */
