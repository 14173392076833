.dashboard-settings-container {
  padding: 40px 5% 68px 48px;
  width: 100%;
  position: relative;
  z-index: 2000;
  height: 100vh;
}

.dashboard-settings-navbar {
  display: flex;
  justify-content: space-between;
}

.dashboard-settings-navbar-left p:nth-child(1) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}
.dashboard-settings-navbar-left p:nth-child(2) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  margin-top: 12px;

  /* grey/text */

  color: #757575;
}

.settings-nav {
  margin-top: 60px;
  display: flex;
}

.settings-border-bottom {
  border-bottom: 1px solid #f8f8f8;
  margin-left: -48px;
  width: 100%;
  /* width:100vw; */
}

.settings-nav p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  cursor: pointer;
  transition: color 250ms ease-in-out;
}

.settings-label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-bottom: 8px;
}

.settings-profile {
}

.settings-Security {
}

.setting-flex {
  display: flex;
  flex-direction: column;
}
.settings-line {
  width: 100%;
  height: 3px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 12px;
  border-radius: 20px;
}

.setting-payouts-form {
  margin-top: 0px !important;
}

.org-border-bottom {
}

@media screen and (max-width: 1350px) {
  .settings-nav p {
    font-size: 16px;
  }
  .settings-label {
    font-size: 16px;
  }
}
