.mobile-container-transactions {
  width: 100%;
  margin: 70px auto;
}
.home-mobile-top {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: 0;
}
.name-circle {
  width: 48px;
  height: 48px;
  background: #d9d9d9;
  border-radius: 50px;

  display: none;
  margin-left: 24px;
}
.name-circle p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.business-name {
  display: flex;
  align-self: center;
  gap: 8px;
  cursor: pointer;
}

.business-name img {
  display: flex;
  align-self: center;
  margin-top: -10px;
  cursor: pointer;
}

.business-name img {
  display: flex;
  align-self: center;
  margin-top: -10px;
  cursor: pointer;
}

.business-name p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-bottom: 12px;
  margin-left: 30px;
}
/* .business-name img{
    width: 4.8px;
    height: 9.6px;
} */
.home-mobile-top-left {
  display: flex;
  gap: 6px;
}
.home-mobile-top-right {
  display: none;
  place-items: center;
}
.home-mobile-top-right img {
  align-self: center;
  display: none;
}
.home-mobile-middle {
  margin-top: 36px;
  width: 100vw;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  gap: 6px;
}
.home-mobile-middle-box {
  width: 90vw;
  height: 95px;
  background-color: white;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  padding: 12px;
  border: 1px solid #c4c4c4;
}
.home-mobile-middle-box h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-mobile-middle-box h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-mobile-middle-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #757575;
}
.home-middle-close p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #121212;
  margin-top: 36px;
  margin-bottom: 24px;
}
.left-table-circle {
  width: 48px;
  height: 48px;
  background: #fff2ea;
  border-radius: 50px;
}
.table-flex {
  display: flex;
  justify-content: space-between;
}
.left-table {
  display: flex;
  gap: 12px;
}
.left-table-details h3 {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}
.left-table-details p {
  display: flex;
  align-items: center;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}
.left-table-details {
  align-self: center;
  display: flex;
  justify-content: space-between;
}
.right-table {
}
.right-table p {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.transactions-mobile-table {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 70vh;
  overflow-y: auto;
}
.pop-body h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #757575;
}
.create-out {
  display: flex;
  gap: 15px;
  height: 70px;
  width: 100%;

  background: white;
  position: fixed;
  bottom: 16%;
  left: 0;
  z-index: 26000;
  padding-left: 24px;
  /* position: absolute;
  bottom: 90px; */
}
.create-out p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  cursor: pointer;

  /* brand/main */

  color: #8f00ff;
}
.create-out img {
  width: 18px;
  height: 19px;
  align-self: center;
}

@media screen and (max-width: 1400px) {
  .business-name {
    zoom: 0.8;
  }
  .business-name p {
    font-size: 26px;
  }

  .transactions-mobile-table {
    height: 60vh;
  }
}
