.pop-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.border-top {
  display: flex;
  justify-content: center;
  margin: 24px 0px;
  display: none;

  background-color: white;
}
.pop-white {
  z-index: 21000;
  position: absolute;
  width: 492px;
  height: 388px;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  padding: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: fade-in 100ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 100ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
  border-bottom: 1px solid #d9d9d9;
  /* padding-top: 114px; */
  overflow: hidden;
}

.pop-white h1 {
  padding-top: 48px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */
  background: white;
  color: #121212;
  padding-bottom: 30px;
  width: 100%;
  /* background: red; */
  padding-left: 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.pop-white-bot {
  padding-top: 40px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */
  background: white;
  color: #121212;
  padding-bottom: 30px;
  width: 100%;
  /* background: red; */
  padding-left: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.filter-radio,
.filter-radio-circle {
  /* display: none !important; */
}

.pop-content {
  padding-top: 100px;
}

.on {
  display: block !important;
}

.pop-white p {
  margin: 0px;
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.switch-outlet-name {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  cursor: pointer;
}

.switch-outlet-outlet {
  margin-left: 84px !important;
  transform: translateY(-10px);
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;

  /* grey/main */

  color: #c4c4c4;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 20:6:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-out-bottom {
  display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 20:5:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

@media screen and (max-width: 1400px) {
  .pop-white {
    height: 100px;
  }
}
