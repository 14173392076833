.kyc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.kyc-qevolt-logo {
  position: absolute;
  left: 60px;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyc-beta {
  margin-top: -8px;
}
/* 
.kyc-submit-button{
    width:528px ;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
} */

.kyc-container::-webkit-scrollbar {
}
.kyc-container {
  margin: auto;
  margin-top: 50px;
  width: 528px;
  height: auto;
  overflow-y: scroll;

  overflow-y: auto;
  width: 600px;
  /* padding-right: 20px; */
}
.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 528px;
}
.form-group input {
  margin: 8px 0px 36px 0px;

  height: 49px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding-left: 24px;
  outline: none;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.kyc-cac {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.business-type-flex {
  display: flex;
  justify-content: space-between;
  width: 528px;
  margin: 48px 0px 25px 0px;
}
.business-type-flex p {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: var(--default-links);
}

.cac-labels {
  display: flex;
  justify-content: space-between;
}

.right-cac {
  display: flex;
}

.cac-view {
  color: #8f00ff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.cac-remove {
  margin-left: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff3737;
  cursor: pointer;
}

.business-heading-mobile {
  display: none;
}
.cac-modify {
  width: 528px;
}

.kyc-cac-container {
  margin-bottom: 36px;
}

.kyc-label {
  color: var(--default-links);
}

.label-flex {
  display: flex;
  justify-content: space-between;
}
.label-flex span {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  text-decoration-line: underline;

  /* text */
  cursor: pointer;
  color: #757575;
}
.business-type-flex span {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  text-decoration-line: underline;

  /* text */

  color: #757575;
}
.business-heading h2 {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.business-heading p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 12px;
}

.form-group label {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}
.business-form button {
  width: 528px;
  height: 52px;
  color: #757575;
  background: #f8f8f8;
  border-radius: 12px;
  border: none;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
}
.business-type-container {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;
}
.business-type {
  display: flex;
}
.business-type p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  justify-content: center;
}
.radio-container {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #8f00ff;
  margin-right: 10px;
}
.radio {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  background: #8f00ff;
}

.form-group ul {
  position: absolute;
  width: 100%;

  list-style: none;
  height: 250px;
  overflow-y: auto;
  background: white;
  z-index: 1;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  transform: translateY(100px);
}
.form-group li {
  padding: 10px 20px;
  color: #121212;
  transition: 0.1s ease-in-out;
}
.form-group li:hover {
  background: #8f00ff;
  color: white;
  transition: 0.1s ease-in-out;
}
.business-heading2 {
  margin-left: 24px;
  margin-top: 48px;
}
.business-heading2 h2 {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.business-body p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  margin-bottom: 24px;

  /* grey/text */

  color: #757575;
}
.business-body {
  padding: 24px;
}

.business-body h1 {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-bottom: 12px;
}

.business-btn {
  width: 90px;
  height: 42px;
  background: #f8f8f8;
  border-radius: 5px;
  border: none;
  font-family: "Poppins";
  color: #757575;
  margin-left: 24px;
  cursor: pointer;
}
.label-img {
  position: absolute;
  right: 30px;
  top: 52px;
  cursor: pointer;
}

.business-preload {
  position: absolute;

  /* padding-bottom: 400px; */
  height: 100vh;

  overflow: hidden;
  top: 0%;
  left: 0%;
  background-color: white;
  width: 200vw;
  color: black;
}
.preload-body {
  width: 300px;
  height: 200px;
  position: absolute;
  left: calc((100vw - 300px) / 2);
  top: calc((100vh - 200px) / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.business-preload p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 30px;
}

.getAccount {
  position: absolute;
  transform: translate(0px, 90px);
  left: 50%;
}

.info-flex {
  width: 528px;
}

.info-flex,
.business-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-info {
  cursor: pointer;
}

.business-info-text {
  margin-left: 8.4px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

@media screen and (max-width: 1400px) {
  .kyc {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    overflow: none;
  }
  .info-flex {
    margin-left: 36px;
  }

  .form-group,
  .business-type-flex,
  .business-type,
  .kyc-submit-button {
    margin-left: auto;
    margin-right: auto;
  }

  .business-type-container {
  }
}

@media screen and (max-width: 575px) {
  .kyc {
    width: 100%;
    margin-top: 40px;
  }
  .business-heading-mobile {
    display: flex;
    justify-content: space-between;
  }
  .business-heading-mobile img {
    transform: translateY(5px);
  }
  .business-heading-mobile-text h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .business-heading-mobile-text p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #757575;
  }
  .pop-body {
    margin-bottom: 36px;
  }
  .pop-body h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .pop-body p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
    margin-top: 12px;
  }
  .pop-body img {
    margin-right: 15px;
  }
  .preload-body p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: -0.3px;

    color: #000000;
  }
  .form-group ul {
    width: 100%;
  }
  .kyc-qevolt-logo {
    display: none;
  }
  .form-group {
    width: 100%;
  }
  .kyc-container {
    width: 90%;
    margin: 0px auto;
    padding: 0;
  }
  .form-group input {
    width: 100%;
    height: 42px;
  }
  .form-group label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;

    /* black/main */

    color: #121212;
  }
  .kyc-label {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;

    /* grey/text */

    color: #757575;
  }
  .business-type-flex {
    width: 100%;
  }
  .business-heading {
    display: none;
  }
  .business-heading h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: none;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    margin-top: 45px;
    color: #121212;
  }
  .business-type-flex p {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;

    /* grey/text */

    color: #757575;
  }
  .business-form button {
    width: 100%;
    height: 54px;
    border-radius: 12px;
    color: #757575;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .kyc .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .business-type-container {
    width: 70%;
  }
}
