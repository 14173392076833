.refunds {
  margin: 40px 0px 68px 48px;

  position: relative;
  z-index: 2000;
}
.dashboard-refunds-navbar-left p:nth-child(1) {
  color: var(--black);
  font-size: 20px;
  line-height: 31px;
  font-weight: 500;
  letter-spacing: -0.3px;
}
.dashboard-refunds-navbar-left p:nth-child(2) {
  color: var(--default-links);
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: -0.3px;
}
.dashboard-refunds-transactions {
  /* height: 50vh;
    overflow-y: auto; */
  /* padding-top: 25px; */
  padding-bottom: 25px;
  background: #ffffff;
  width: 100%;
  border-radius: 5px;
  margin-top: 60px;
  /* border: 1px solid #D9D9D9;
    border-radius: 20px; */
}
.dashboard-refunds-transaction-nav {
  display: flex;
  justify-content: space-between;
  margin: 60px 0px 48px 0px;
}
.dashboard-refunds-transaction-nav-heading {
  display: flex;
}

.dashboard-refunds-transaction-nav p {
  color: var(--default-links);
}
.dashboard-refunds-transaction-nav-heading p {
  color: var(--black);
  font-size: 15px;
}
.dashboard-refunds-transaction-nav-heading img {
  margin-right: 18px;
}

.dashboard-refunds-fetch-flex:nth-child(1) {
  border-top: none;
}
.dashboard-refunds-fetch-flex {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #efefef;
  cursor: pointer;
  transition: background 300ms ease-in-out;
  padding: 0px 24px;
}
.dashboard-refunds-fetch-flex:hover {
  background: #f8f8f8;
  transition: background 300ms ease-in-out;
}

.dashboard-refunds-transactions-list {
  padding-left: 0px;
}

.dashboard-refunds-transactions-list p {
  width: 14vw;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  height: 75px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}
.dashboard-refunds-transaction-header-flex {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 13px 24px;
}
.dashboard-refunds-transaction-header-flex p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  width: 14vw;
}

@media screen and (max-width: 575px) {
  .refunds {
    margin: 0;
    height: 80vh;

    position: fixed;
    z-index: 100;
  }
  .dashboard-refunds-navbar {
    /* position: fixed;
       
        position:-webkit-sticky;
        top: 0;
        -webkit-overflow-scrolling:touch; */
    height: 85px;
    background-color: white;
    width: 100vw;
    z-index: 7000;
  }
  .dashboard-refunds-navbar-left p:nth-child(1) {
    position: absolute;
    top: 41px;
    left: 70px;

    font-size: 16px;

    letter-spacing: -0.3px;

    color: #121212;
  }
  .dashboard-refunds-navbar-left p:nth-child(2) {
    display: none;
  }
  .dashboard-refunds-navbar-right input {
    display: none;
  }
  .dashboard-refunds-navbar-right .search {
    position: absolute;
    top: 13px;
    left: 0;
  }

  .dashboard-refunds-navbar-right-image1 {
    border: none;
    margin-left: 30px;
  }
  .dashboard-refunds-container {
    margin: 0;
  }
  .dashboard-refunds-transaction-header-flex {
    width: 100%;

    padding: 24px 24px 13px 24px;
    margin-bottom: 0px;
    background: #f8f8f8;
  }
  .dashboard-refunds--transaction-header-flex p {
    color: #757575;
  }

  .dashboard-refunds-transaction-header-flex p:nth-child(1),
  .dashboard-refunds-transaction-header-flex p:nth-child(4),
  .dashboard-refunds-transaction-header-flex p:nth-child(5) {
    display: none;
  }
  .dashboard-refunds-transactions-list {
    padding: 0;
  }
  .dashboard-refunds-fetch-flex {
    width: 100%;
  }
  .dashboard-refunds-fetch-flex .dashboard-refunds-transactions-list {
    width: 50%;
    padding: 0px 24px;
  }
  .dashboard-refunds-fetch-flex
    .dashboard-refunds-transactions-list:nth-child(2)
    p {
    width: 100%;
    text-align: left;
    font-size: 14px;
  }
  .dashboard-refunds-fetch-flex
    .dashboard-refunds-transactions-list:nth-child(3)
    p {
    width: 100%;
    text-align: right;
    font-size: 14px;
  }
  .dashboard-refunds-fetch-flex
    .dashboard-refunds-transactions-list:nth-child(1),
  .dashboard-refunds-fetch-flex
    .dashboard-refunds-transactions-list:nth-child(4),
  .dashboard-refunds-fetch-flex
    .dashboard-refunds-transactions-list:nth-child(5) {
    display: none;
  }

  .dashboard-modal-flex-container {
    padding: 24px 24px 0px 24px;
  }
  .dashboard-modal-flex p:nth-child(1) {
    font-weight: 300;
  }
  .dashboard-modal-buttons {
    padding: 0px 24px;
  }
  .dashboard-modal-buttons button:nth-child(2) {
    display: none;
  }
  .refund-mobile {
    display: block;
    padding: 0;
    gap: 10px;
    width: 90px;
    height: 45px;
    background: #8f00ff;
    border-radius: 5px;
    border: none;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
  .dashboard-modal-buttons button:nth-child(1) {
    padding: 0;
    width: 90px;
  }
  .dashboard-refunds-transactions {
    padding: 0;
    height: 90%;
    overflow-y: hidden;
    width: 90vw;
    margin: 20px auto 0px auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    box-shadow: none;
  }
  .dashboard-refunds-transaction-nav {
    width: 90vw;
    margin: 50px auto;
  }
  .dashboard-refunds-transaction-nav .refunds-text-none {
    display: none;
  }
  .dashboard-refunds-pagination {
    display: none;
  }
  .dashboard-refunds-transactions-list:nth-child(2) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 500;
  }
  .dashboard-refunds-transactions-list:nth-child(3) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 500;
  }
  .dashboard-refunds-fetch-flex:hover {
    background: none;
    transition: none;
  }
}
