.dashboard-sidebar img {
  filter: invert(50%) sepia(8%) saturate(0%) hue-rotate(161deg) brightness(88%)
    contrast(80%);
  width: 20px;
}
.dashboard-sidebar-heading img {
  filter: invert(0%);
  width: 40px;
}

.dashboard-sidebar {
  font-family: "Poppins", sans-serif;
  width: 240px;
  background: #fdfdfd;
  transition: 0.3s ease-in-out;
  height: 100vh;

  position: sticky;
  top: 0;
  z-index: 0;
}

.dashboard-sidebar-heading {
  margin: 40px 0px 70px 30px;
}
.dashboard-sidebar-tabs {
}
.dashboard-sidebar-links p:hover {
  color: var(--primary-color);
}
.dashboard-sidebar-links:hover p {
  color: var(--primary-color);
}
.dashboard-sidebar-links:hover img {
  filter: invert(0%);
}

.dashboard-sidebar-heading p:nth-child(1) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-bottom: 12px;
  opacity: 0;
}
.dashboard-sidebar-heading p:nth-child(2) {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/main */

  color: #c4c4c4;
}
.dashboard-sidebar-links {
  position: relative;
  width: 240px;
  padding-left: 30px;
  display: flex;
  padding-top: 5%;
  margin-top: 10%;

  z-index: 3000;
}

.dashboard-sidebar-line {
  width: 0px;
  height: 45px;
  background: var(--primary-color);
  border-radius: 10px;
  position: absolute;
  top: -1px;
  left: 0;
  display: none;
}
.dashboard-sidebar-links.active p {
  color: var(--primary-color);
}
.dashboard-sidebar-links.active img {
  filter: invert(0%);
}
.dashboard-sidebar-links.active .dashboard-sidebar-line {
  display: block;
}

.dashboard-sidebar-links p,
.sidebar-support p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-left: 14px;
  cursor: pointer;
}
.dashboard-sidebar-links p:hover {
  color: var(--primary-color);
}

.sidebar-support {
  position: absolute;
  bottom: 100px !important;
  display: flex;
  cursor: pointer;
}

.sidebar-support p {
  color: #757575;
}
.sidebar-support:hover p {
  color: var(--primary-color);
}

.sidebar-support {
  width: 240px;
  padding-left: 30px;
}

.sidebar-support img {
  filter: invert(50%) sepia(8%) saturate(0%) hue-rotate(161deg) brightness(88%)
    contrast(80%);
  width: 20px;
}

.sidebar-support:hover img {
  filter: invert(44%) sepia(100%) saturate(7479%) hue-rotate(270deg)
    brightness(89%) contrast(138%);
}

.dashboard-sidebar-logout {
  position: absolute;
  bottom: 40px;
  margin-left: 30px;
}
.dashboard-sidebar-logout a {
  display: flex;
}
.dashboard-sidebar-logout img {
  filter: invert(0%);
}
.dashboard-sidebar-logout p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;

  /* error/main */

  color: #ff3737;
  margin-left: 14px;
}

.logoutModal-container {
  position: absolute;
  left: 50%;
  top: 50%;
}

@media screen and (max-width: 1400px) {
  .dashboard-sidebar-tabs {
    zoom: 0.85;
  }

  /* .dashboard-sidebar-links p,
  .sidebar-support p {
    font-size: 22px;
  }
  .dashboard-sidebar-logout p {
    font-size: 22px;
  } */
}

@media screen and (max-width: 975px) {
  .dashboard-sidebar {
    display: none;
  }
}
