.mobile-navigation {
  display: flex;

  /* border-bottom: 1px solid #d9d9d9; */

  left: 0;
  /* transform: translateY(41px); */
  /* padding: 0px 5%; */
  margin-top: 60px;
}

.mobile-navigation div {
}

.mobile-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(0px);
  margin-right: 96px;
}
.mobile-tab p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  cursor: pointer;
}
.mobile-tab h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #4bb543;
}
.mobile-tab .circle-count {
  width: 28px;
  height: 19px;
  background: #f2faf1;
  border-radius: 100px;
  display: grid;
  place-items: center;
  transform: translate(3px, 2px);
}

.mobile-tab-grp {
  display: flex;
  padding-bottom: 12px;
}

.absolute-pop {
  position: absolute;
  right: 17px;
  bottom: -8vh;
}
.invites-mid {
  margin: 36px 0px;
}
.invites-mid-intro {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #757575;
  margin-bottom: 24px;
}

.spin {
  -webkit-animation: spin 0.9s linear infinite;
  animation: spin 0.9s linear infinite;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@supports (-webkit-touch-callout: none) {
  .absolute-pop {
    bottom: 6vh;
  }
}

@supports not (-webkit-touch-callout: none) {
  .absolute-pop {
    bottom: 0vh;
  }
}

.nea {
  display: flex;
  justify-content: space-between;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 14px;
}

.nea div {
  width: 33%;
}

.org-table-action,
.right-table {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.org-table-email,
.middle-table-details {
  /* margin-left: 10%; */
  transform: translateX(20%);
}

.left-table-details,
.middle-table-details,
.right-table-details {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  width: 33%;
}

.table-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.right-table-details {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;

  /* error/main */

  color: #ff3737;
}

.right-table {
  cursor: pointer;
}

.tab-border {
  width: 100%;
  height: 3px;
  background: #8f00ff;
  border-radius: 20px;
}
