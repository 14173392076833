.setting-profile-form {
  margin-top: 35px;
}

.label {
  color: #757575;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  margin-top: 36px;
  /* margin-bottom:1rem; */
}

.settingsInputBox {
  width: 528px !important;
  height: 50px !important;
  padding-left: 20px;
  color: var(--black);

  border: 1px solid rgba(229, 231, 235);
  margin-top: 13px;
  border-radius: 12px;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
}

.setting-profile-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.profile-save-button {
  width: 195px;
  height: 60px;
  margin-top: 48px;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  border-radius: 12px;
  font-weight: 500;

  font-family: "Qanelas Soft";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.3px;
  transition: all 200ms ease;
}

.profile-loading-save-button {
  width: 160px;
  height: 40px;
  margin-top: 48px;
  background-color: #f8f8f8;
  color: #757575;
  border: 0;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}

.profile-loading-save-button img {
  width: 40px;
}

.settingEmailError {
  margin-top: 35px;
}

#modalpass {
  background-color: #ffffff;

  position: absolute;
  left: calc(50% - 510px);
  top: 20%;
  border-radius: 10px;
  width: 530px;
  height: 520px;
}
