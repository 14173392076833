.success{
    bottom:-100%;
    animation-name: errorEnter;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position:absolute;
    
}


 




@keyframes errorEnter{
    0%{
        bottom:-50%;
    }
    100%{
        bottom:0%;
    }
}
@keyframes errorLeave{
    0%{
        bottom:0%;
    }
    100%{
        bottom:-50%;
    }
}
