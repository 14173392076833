.logout-cover{
    width:100%;
    height:100%;
    position:absolute;
    background-color: rgba(0, 0, 0, .4);
    left: 50%;
    top:50%;
    transform: translate(-50% , -50%);
    z-index:10000;

}