.supportModal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: red;
  width: 492px;
  height: 427px;
  transform: translate(-50%, -50%);
  z-index: 100000;
  background: #ffffff;
  -webkit-animation: fade-in-fwd 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  border-radius: 10px;
}
@-webkit-keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.supportModal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;

  padding-left: 24px;
  margin-top: 48px;
  padding-bottom: 12px;
}

.supportModal-question {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
  padding-left: 24px;
  margin-top: 36px;
}

.supportModal-buttons {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}

.supportModal-cancel {
  background: #f8f8f8;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  color: #757575;
  transition: all 200ms ease;
}

.supportModal-cancel p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */
  padding: 12px 24px;
}
.supportModal-logout {
  background: #ff3737;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.supportModal-logout p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  padding: 12px 24px;
}

.supportModal-header {
  margin-bottom: 36px;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* black/main */

  color: #121212;
}

.supportModal-support {
  margin-left: 24px;
  display: flex;
  margin-bottom: 36px;
}

.supportModal-support p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}
.supportModal-support a {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  /* grey/text */

  color: #757575;
}

.supportModal-support img {
  margin-right: 14.4px;
}
